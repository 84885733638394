import React, { FunctionComponent, useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import { map, prop, isNil, isEmpty } from 'ramda'

import { calculateBounds } from './Map.utils'
import { Marker, MarkerType } from './Marker'
import { InfoWindow } from './InfoWindow'

interface Props {
  markers?: MarkerType[]
  onMarkerClick: (key: number) => void
}

const Map: FunctionComponent<Props> = ({ markers, onMarkerClick }) => {
  const [gmap, setGmap] = useState({} as any)
  const [maps, setMapsApi] = useState({} as any)
  const center = {
    lat: parseFloat(process.env.REACT_APP_GMAP_DEFAULT_LAT as string),
    lng: parseFloat(process.env.REACT_APP_GMAP_DEFAULT_LNG as string),
  }
  const zoom = parseInt(process.env.REACT_APP_GMAP_DEFAULT_ZOOM as string)

  const apiHasLoaded = ({ map, maps }: any) => {
    setGmap(map)
    setMapsApi(maps)
  }

  const updateBounds = () => {
    if (isEmpty(markers) || isNil(markers)) {
      return
    }

    const bounds = calculateBounds(markers)
    maps.LatLng &&
      gmap.fitBounds(
        new maps.LatLngBounds(
          new maps.LatLng(bounds.ne.lat, bounds.ne.lng),
          new maps.LatLng(bounds.sw.lat, bounds.sw.lng)
        ),
        parseInt(process.env.REACT_APP_ENV_GMAP_BOUNDS_PADDING as string)
      )
  }

  useEffect(updateBounds, [markers])

  const renderMarker = (marker: MarkerType) => (
    <Marker
      key={prop('Id', marker)}
      icon={prop('Type', marker)}
      lat={prop('Lat', marker)}
      lng={prop('Lng', marker)}
    />
  )

  const createMapOptions = () => ({
    panControl: false,
    mapTypeControl: false,
    scrollwheel: true,
    fullscreenControl: false,
    zoomControl: true,
    clickableIcons: false,
    maxZoom: parseInt(process.env.REACT_APP_GMAP_MAX_ZOOM as string),
    styles: [
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      },
    ],
  })

  const renderMarkers = map(renderMarker)

  return (
    // Needs inline height style to be able to render
    <div style={{ height: '100vh', position: 'relative' }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_API_KEY as string,
        }}
        center={center}
        zoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={apiHasLoaded}
        resetBoundsOnResize
        options={createMapOptions}
        onChildClick={onMarkerClick}
      >
        {!isEmpty(markers) && renderMarkers(markers)}
      </GoogleMapReact>
      <InfoWindow />
    </div>
  )
}

export default Map
