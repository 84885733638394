import {
  apiLoadNavigationFlow,
  apiLoadNavigationSuccessFlow,
} from '../../modules/Navigation'
import { closeMapFlow } from '../../modules/CloseButton'

import api from '../api'

export default [
  apiLoadNavigationFlow(api),
  apiLoadNavigationSuccessFlow,
  closeMapFlow,
]
