import { connect } from 'react-redux'

import Component from './CloseButton.component'
import { closeMap } from './CloseButton.reducer'

const mapStateToProps = null
const mapDispatchToProps = {
  onCloseMap: closeMap,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
