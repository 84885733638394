import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import InfoWindow from './InfoWindow.component'
import infoWindowSelector from '../InfoWindow.selector'
import showInfoWindowSelector from '../showInfoWindow.selector'
import { hideInfoWindow } from '../Map.reducer'
import { RootState } from '../../../common/redux/types'

const mapStateToProps = (state: RootState) => ({
  infoWindow: infoWindowSelector(state),
  showInfoWindow: showInfoWindowSelector(state),
})

const mapDispatchToProps = {
  onHide: hideInfoWindow,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(InfoWindow))
