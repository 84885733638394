import { createSelector } from 'reselect'
import { prop } from 'ramda'

import { RootState } from '../../common/redux/types'
import { NavigationState } from './Navigation.reducer'

const selectNavigation = (state: RootState): NavigationState =>
  prop('navigation', state)

const navigationSelector = createSelector(selectNavigation, (navigation) => ({
  navigation,
}))

export default navigationSelector
