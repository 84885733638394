import { createReducer, createAction } from '@reduxjs/toolkit'
import { assoc, prop, compose } from 'ramda'

export interface MapState {
  showInfoWindow: boolean
  activeMarker: number
}

export const SET_ACTIVE_MARKER = '[map] set active marker'
export const setActiveMarker = createAction(
  SET_ACTIVE_MARKER,
  (payload: number) => ({
    payload,
  })
)

export const SHOW_INFO_WINDOW = '[map] show info window'
export const showInfoWindow = createAction(SHOW_INFO_WINDOW)

export const HIDE_INFO_WINDOW = '[map] hide info window'
export const hideInfoWindow = createAction(HIDE_INFO_WINDOW)

export const INITIAL_STATE: MapState = {
  showInfoWindow: false,
  activeMarker: 0,
}

const reducer = createReducer(INITIAL_STATE, (builder) =>
  builder
    .addCase(setActiveMarker, (state, action) =>
      compose(
        assoc('showInfoWindow', true),
        assoc('activeMarker', parseInt(prop('payload', action), 10))
      )(state)
    )
    .addCase(showInfoWindow, (state) => assoc('showInfoWindow', true, state))
    .addCase(hideInfoWindow, (state) => assoc('showInfoWindow', false, state))
)

export default reducer
