import { createReducer, createAction } from '@reduxjs/toolkit'
import { assoc, prop } from 'ramda'

interface Item {
  Id: number
  Title: string
  Lat: number
  Lng: number
  Type: string
  Image: string
  Address: string
  Url: string
  Icon: string
}

interface Subcategory {
  [key: string]: {
    items: Item[]
  }
}

interface NavItems {}

export interface ActiveCategory {
  category: string
  subcategory: string
}

export interface NavigationState {
  active: ActiveCategory
  navItems: NavItems
}

export const API_LOAD_NAVIGATION = '[navigation] load navigation'
export const apiLoadNavigation = createAction(API_LOAD_NAVIGATION)
export const API_LOAD_NAVIGATION_SUCCESS =
  '[navigation] load navigation success'
export const apiLoadNavigationSuccess = createAction(
  API_LOAD_NAVIGATION_SUCCESS,
  (payload: NavItems) => ({
    payload,
  })
)
export const API_LOAD_NAVIGATION_FAILURE =
  '[navigation] load navigation failure'
export const apiLoadNavigationFailure = createAction(
  API_LOAD_NAVIGATION_FAILURE,
  (payload: any) => ({
    payload,
  })
)

export const SET_NAVIGATION_ITEMS = '[navigation] set navigation items'
export const setNavigationItems = createAction<
  NavItems,
  typeof SET_NAVIGATION_ITEMS
>(SET_NAVIGATION_ITEMS)

export const SET_ACTIVE = '[navigation] set active'
export const setActive = createAction<ActiveCategory, typeof SET_ACTIVE>(
  SET_ACTIVE
)

export const CLEAR_ACTIVE_NAVIGATION = '[navigation] clear'
export const clearActiveNavigation = createAction(CLEAR_ACTIVE_NAVIGATION)

export const INITIAL_STATE: NavigationState = {
  active: {
    category: '',
    subcategory: '',
  },
  navItems: {},
}

const reducer = createReducer(INITIAL_STATE, (builder) =>
  builder
    .addCase(setNavigationItems, (state, action) =>
      assoc('navItems', prop('payload', action), state)
    )
    .addCase(setActive, (state, action) =>
      assoc('active', prop('payload', action), state)
    )
    .addCase(clearActiveNavigation, (state) =>
      assoc(
        'active',
        {
          category: '',
          subcategory: '',
        },
        state
      )
    )
)

export default reducer
