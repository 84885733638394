import React, { FunctionComponent } from 'react'
import DOMPurify from 'dompurify'
import { prop } from 'ramda'

import styles from './InfoWindow.module.css'
import { WithTranslation } from 'react-i18next'

interface Props {
  infoWindow: {}
  showInfoWindow: boolean
  onHide: any
}

const InfoWindow: FunctionComponent<Props & WithTranslation> = ({
  t,
  infoWindow,
  showInfoWindow,
  onHide,
}) => {
  const infoWindowImage = {
    backgroundImage: `url(${prop('Image', infoWindow)})`,
  }
  return (
    <div
      className={`${styles.InfoWindow} ${showInfoWindow ? styles.Show : ''}`}
    >
      <div className={styles.Image} style={infoWindowImage}>
        {prop('Title', infoWindow)}
      </div>
      <div className={styles.Content}>
        <h2>{prop('Title', infoWindow)}</h2>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(prop('Address', infoWindow) || ''),
          }}
        />
        {prop('Url', infoWindow) && (
          <a
            href={prop('Url', infoWindow)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Website')} &rarr;
          </a>
        )}
      </div>
      <button className={styles.CloseTooltip} onClick={onHide}>
        <span></span>
        <span></span>
      </button>
    </div>
  )
}

export default InfoWindow
