import React, { FunctionComponent } from 'react'

import styles from './CloseButton.module.css'

interface Props {
  onCloseMap: () => void
}

const CloseButton: FunctionComponent<Props> = ({ onCloseMap }) => (
  <div className={styles.Wrapper}>
    <button className={styles.Button} onClick={onCloseMap}>
      <div className={styles.Line}></div>
      <div className={styles.Line}></div>
    </button>
  </div>
)

export default CloseButton
