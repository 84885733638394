import React, { FunctionComponent } from 'react'

import styles from './Marker.module.css'

export interface Marker {
  Id: number
  Lat: number
  Lng: number
  Icon: string
  Title?: string
  Image?: string
  Address?: string
  Type?: string
}

interface Props {
  icon: string
  lat: number
  lng: number
}

const Marker: FunctionComponent<Props> = ({ icon }) => (
  <div className={`${styles.Marker} ${styles[icon]}`}></div>
)

export default Marker
