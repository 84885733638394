import { createSelector } from 'reselect'
import {
  prop,
  path,
  compose,
  values,
  flatten,
  map,
  propEq,
  find,
  pick,
  unless,
  isNil,
} from 'ramda'

const selectMap = prop('map')
const selectNavItems = path(['navigation', 'navItems'])

const findById = (navItems: any) => (id: number) =>
  compose(
    unless(isNil, pick(['Id', 'Address', 'Title', 'Url', 'Image'])),
    find(propEq('Id', id)),
    flatten,
    map(prop('Items')),
    flatten,
    map(values),
    values
  )(navItems)

const infoWindowSelector = createSelector(
  [selectMap, selectNavItems],
  (mapData, navItems) =>
    compose(findById(navItems), prop('activeMarker'))(mapData) || {}
)

export default infoWindowSelector
