import Navigation from './Navigation.container'
import navigation, { NavigationState as State } from './Navigation.reducer'
import {
  apiLoadNavigationFlow,
  apiLoadNavigationSuccessFlow,
} from './Navigation.middleware'

export default Navigation
export { navigation, apiLoadNavigationFlow, apiLoadNavigationSuccessFlow }

export type NavigationState = State
