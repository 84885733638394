import { connect } from 'react-redux'

import Navigation from './Navigation.component'
import { setActive, clearActiveNavigation } from './Navigation.reducer'
import navigationSelector from './Navigation.selectors'

const mapStateToProps = navigationSelector
const mapDispatchToProps = {
  onSetActive: setActive,
  onClearActiveNavigation: clearActiveNavigation,
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
