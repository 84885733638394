import { isEmpty, isNil } from 'ramda'

import { CLOSE_MAP } from './CloseButton.reducer'
import { getParameterByName, inIframe } from './CloseButton.utils'

export const closeMapFlow = () => (next: any) => (action: any) => {
  next(action)

  const { type } = action
  if (type === CLOSE_MAP) {
    const referer = getParameterByName('referer')

    if (isNil(referer) || isEmpty(referer)) {
      return window.location.assign(
        process.env.REACT_APP_DEFAULT_RETURN as string
      )
    }

    const iframe = document.getElementById('js-iframe') as HTMLIFrameElement

    if (!iframe || inIframe()) {
      // Handle case where iframe not found

      window.location.assign(referer as string)
      return
    }

    const contentWindow = iframe.contentWindow

    if (!contentWindow) {
      return
    }

    contentWindow.postMessage({ referer: referer }, 'https://www.park2020.com')
  }
}
