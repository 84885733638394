import React, { useRef, FunctionComponent, MutableRefObject } from 'react'
import { map, prop, keys, equals } from 'ramda'

import { getCategories, mapIndexed } from './navigation.utils'
import { ActiveCategory, NavigationState } from './Navigation.reducer'
import styles from './Navigation.module.css'

type Props = {
  onSetActive: ({ category, subcategory }: ActiveCategory) => void
  onClearActiveNavigation: () => void
  navigation: NavigationState
}

const Navigation: FunctionComponent<Props> = ({
  onSetActive,
  navigation,
  onClearActiveNavigation,
}) => {
  const { active, navItems } = navigation
  const categories = getCategories(navItems)
  const categoryRefs: MutableRefObject<HTMLUListElement[]> = useRef([])

  const renderSubCategory = (category: string) => (subcategory: string) => (
    <li
      key={subcategory}
      className={`${
        equals(subcategory, prop('subcategory', active)) ? styles.Active : ''
      }`}
    >
      <button
        onClick={() =>
          onSetActive({
            category,
            subcategory,
          })
        }
      >
        {subcategory}
      </button>
    </li>
  )

  const renderSubCategories = (category: string) =>
    map(renderSubCategory(category))

  const renderCategory = (category: string, index: number) => (
    <li
      key={category}
      className={`${styles.Category} ${
        equals(category, prop('category', active)) ? styles.Active : ''
      }`}
    >
      <div className={styles.CategoryHeader}>
        <button
          onClick={() =>
            onSetActive({
              category,
              subcategory: '',
            })
          }
        >
          {category}
        </button>
        <button
          onClick={
            equals(category, prop('category', active))
              ? onClearActiveNavigation
              : () =>
                  onSetActive({
                    category,
                    subcategory: '',
                  })
          }
          className={styles.MoreButton}
        >
          <div>
            <span></span>
            <span></span>
          </div>
        </button>
      </div>
      <ul
        className={styles.SubCategories}
        ref={(el: HTMLUListElement) => (categoryRefs.current[index] = el)}
        style={{
          height: `${
            prop('category', active) === category
              ? categoryRefs.current[index]?.scrollHeight
              : 0
          }px`,
        }}
      >
        {renderSubCategories(category)(keys(prop(category, navItems)))}
      </ul>
    </li>
  )

  const renderCategories = mapIndexed(renderCategory, categories)

  return (
    <div className={styles.Navigation}>
      <ul className={styles.Categories}>{renderCategories}</ul>
    </div>
  )
}

export default Navigation
