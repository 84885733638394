import { createSelector } from 'reselect'
import { prop } from 'ramda'

const selectMap = prop('map')

const showInfoWindowSelector = createSelector(
  [selectMap],
  (mapData) => prop('activeMarker')(mapData) && prop('showInfoWindow')(mapData)
)

export default showInfoWindowSelector
