import { createSelector } from 'reselect'
import { prop, isEmpty, path, compose } from 'ramda'

import { combineCategoryItems, combineAllItems } from './Map.utils'
import { NavigationState } from '../Navigation/Navigation.reducer'

const selectNavigation = prop('navigation')

const markerSelector = createSelector(
  [selectNavigation],
  (navigation: NavigationState) => {
    const { active, navItems } = navigation

    if (
      isEmpty(prop('category', active)) &&
      isEmpty(prop('subcategory', active))
    ) {
      return {
        markers: combineAllItems(navItems),
      }
    }

    if (isEmpty(prop('subcategory', active))) {
      return {
        markers: combineCategoryItems(prop(prop('category', active), navItems)),
      }
    }

    return {
      markers: compose(
        prop('Items'),
        path([prop('category', active), prop('subcategory', active)])
      )(navItems),
    }
  }
)

export default markerSelector
