import React, { FunctionComponent, useState } from 'react'
import { WithTranslation } from 'react-i18next'

import Logo from '../Logo/'
import Navigation from '../Navigation'
import { Map } from '../Map'
import { CloseButton } from '../CloseButton'
import styles from './App.module.css'

const App: FunctionComponent<WithTranslation> = ({ t }) => {
  const [showNav, setShowNav] = useState(false)

  const toggleNav = () => setShowNav(!showNav)

  return (
    <div className={styles.App}>
      <div className={`${styles.AppNavigation} ${showNav ? styles.Open : ''}`}>
        <div className={styles.Header}>
          <Logo />
          <div>
            <h2>{t('siteTitle')}</h2>
            <button
              className={styles.ShowNavigation}
              onClick={toggleNav}
            ></button>
          </div>
        </div>
        <div className={styles.Navigation}>
          <Navigation />
        </div>
      </div>
      <div className={styles.MapContainer}>
        <Map />
      </div>
      <CloseButton />
    </div>
  )
}

export default App
