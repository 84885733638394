import { AnyAction } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import { prop } from 'ramda'

import {
  API_LOAD_NAVIGATION,
  API_LOAD_NAVIGATION_SUCCESS,
  apiLoadNavigationSuccess,
  setNavigationItems,
  apiLoadNavigationFailure,
} from './Navigation.reducer'

//---------------------------------
// load data
//---------------------------------

export const apiLoadNavigationFlow =
  (api: any) =>
  ({ dispatch }: any) =>
  (next: (action: AnyAction) => void) =>
  (action: AnyAction): void => {
    next(action)
    const { type } = action
    if (type === API_LOAD_NAVIGATION) {
      api
        .get('/')
        .then(({ data }: AxiosResponse) => {
          dispatch(apiLoadNavigationSuccess(data))
        })
        .catch(({ response }: AxiosError) =>
          dispatch(apiLoadNavigationFailure(prop('data', response)))
        )
    }
  }

export const apiLoadNavigationSuccessFlow =
  ({ dispatch }: any) =>
  (next: (action: AnyAction) => void) =>
  (action: AnyAction): void => {
    next(action)
    const { type, payload } = action

    if (type === API_LOAD_NAVIGATION_SUCCESS) {
      dispatch(setNavigationItems(payload))
    }
  }
