import {
  values,
  compose,
  map,
  prop,
  flatten,
  reduce,
  path,
  has,
  ifElse,
  concat,
} from 'ramda'
import { MarkerType } from './Marker'

const f = ifElse(
  has('Items'),
  prop('Items'),
  compose(
    reduce((acc: [], x: {}) => concat(acc, f(x)), []),
    values
  )
)

export const combineAllItems = f

export const combineCategoryItems = compose(flatten, map(prop('Items')), values)

export const calculateBounds = reduce(
  (bounds: any, marker: MarkerType) => {
    const newBounds = {
      ne: {
        lat:
          prop('Lat', marker) < path(['ne', 'lat'], bounds)
            ? prop('Lat', marker)
            : path(['ne', 'lat'], bounds),
        lng:
          prop('Lng', marker) < path(['ne', 'lng'], bounds)
            ? prop('Lng', marker)
            : path(['ne', 'lng'], bounds),
      },
      sw: {
        lat:
          prop('Lat', marker) > path(['sw', 'lat'], bounds)
            ? prop('Lat', marker)
            : path(['sw', 'lat'], bounds),
        lng:
          prop('Lng', marker) > path(['sw', 'lng'], bounds)
            ? prop('Lng', marker)
            : path(['sw', 'lng'], bounds),
      },
    }

    return newBounds
  },
  {
    ne: {
      lat: 90,
      lng: 180,
    },
    sw: {
      lat: 0,
      lng: 0,
    },
  }
)
