import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import 'normalize.css'

import store from './common/redux/store'
import './assets/styles/theme.css'
import './common/i18n'
import App from './modules/App'
import * as serviceWorker from './serviceWorker'
import { apiLoadNavigation } from './modules/Navigation/Navigation.reducer'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

store.dispatch(apiLoadNavigation())
