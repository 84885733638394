import { connect } from 'react-redux'

import Map from './Map.component'
import { setActiveMarker } from './Map.reducer'

import markerSelector from './Markers.selector'

const mapStateToProps = markerSelector
const mapDispatchToProps = {
  onMarkerClick: setActiveMarker,
}

export default connect(mapStateToProps, mapDispatchToProps)(Map)
